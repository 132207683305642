import request from '@/utils/http'

/**
 * 存储引擎列表
 * @param payload
 */
export function engine_list(payload) {
    return request({ url: '/Storage/engineList', method: 'post', data: payload })
}

/**
 * 更新存储引擎配置信息
 * @param payload
 */
export function update_engine(payload) {
    return request({ url: '/Storage/updateEngine', method: 'post', data: payload })
}

/**
 * 改变存储引擎
 * @param payload
 */
export function change(payload) {
    return request({ url: '/Storage/change', method: 'post', data: payload })
}

/**
 * 分组列表
 * @param payload
 */
export function group_list(payload) {
    return request({ url: '/Storage/groupList', method: 'post', data: payload })
}

/**
 * 文件列表
 * @param payload
 */
export function file(payload) {
    return request({ url: '/Storage/fileList', method: 'post', data: payload })
}

/**
 * 图片库分类
 * @param payload
 */
export function photo(payload) {
    return request({ url: '/Storage/photo', method: 'post', data: payload })
}

/**
 * 修改文件
 * @param payload
 */
export function update_file(payload) {
    return request({ url: '/Storage/updateFile', method: 'post', data: payload })
}

/**
 * 删除文件
 * @param payload
 */
export function delete_file(payload) {
    return request({ url: '/Storage/deleteFile', method: 'post', data: payload })
}

/**
 * 创建分组
 * @param payload
 */
export function create_group(payload) {
    return request({ url: '/Storage/createGroup', method: 'post', data: payload })
}

/**
 * 编辑分组
 * @param payload
 */
export function update_group(payload) {
    return request({ url: '/Storage/updateGroup', method: 'post', data: payload })
}

/**
 * 删除分组
 * @param payload
 */
export function delete_group(payload) {
    return request({ url: '/Storage/deleteGroup', method: 'post', data: payload })
}

/**
 * 上传图片
 * @param payload
 */
export let upload = process.env.VUE_APP_API_BASE_URL + '/storage/Upload/image';